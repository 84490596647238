body {
    font-family: "Montserrat", sans-serif !important;
    overflow: hidden;
}

/* Стили для основного контейнера полосы прокрутки */
::-webkit-scrollbar {
    width: 5px; /* Ширина полосы прокрутки */
  }
  
  /* Стили для трека (основного фона) полосы прокрутки */
  ::-webkit-scrollbar-track {
    background-color: rgb(31 41 55 / var(--tw-bg-opacity)); /* Цвет трека */
  }
  
  /* Стили для бегунка (ползунка) полосы прокрутки */
  ::-webkit-scrollbar-thumb {
    background-color: #1d4ed8; /* Цвет бегунка */
    border-radius: 5px; /* Закругление углов бегунка */
  }
  
  /* Стили для кнопок в конце полосы прокрутки (необязательно) */
  ::-webkit-scrollbar-button {
    background-color: #ccc; 
    display: none;
  }

  .card-1 {
    background-image: linear-gradient(135deg, rgb(11, 99, 246), rgb(0, 60, 197));
  }

  .card-2 {
    background-image: linear-gradient(135deg, rgb(115, 125, 254), rgb(255, 202, 201));
  }
  .card-3 {
    background-image: linear-gradient(135deg, rgb(214, 255, 127), rgb(0, 179, 204));
  }
